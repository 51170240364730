import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomComponent } from '../components/dialogs/dialog-custom/dialog-custom.component';
import { DialogPrivacypolicyComponent } from '../components/dialogs/dialog-privacypolicy/dialog-privacypolicy.component';	
import { DialogCustomData } from '../models/dialog-custom-data';
import { DialogAuthComponent } from '../components/dialogs/dialog-auth/dialog-auth.component';
import { DialogLoginAdminComponent } from '../components/dialogs/dialog-login-admin/dialog-login-admin.component';
import { DeliveryDialogComponent } from '../components/dialogs/delivery-dialog/delivery-dialog.component';
import { DialogChangeOrderComponent } from '../components/dialogs/dialog-change-order/dialog-change-order.component';
import { DialogAuctionComponent } from '../components/dialogs/dialog-auction/dialog-auction.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService { 

  constructor(private matDialog: MatDialog) {}
  openDialogCustom(data: DialogCustomData) {
    //console.log(data)
    this.matDialog.open(DialogCustomComponent, {disableClose: true, data: data});
  }
   openDialogPrivacyPolicy(){
    this.matDialog.open(DialogPrivacypolicyComponent)
   }
   openDialogAuth(wallet: string){
    this.matDialog.open(DialogAuthComponent, {disableClose: true, data: wallet})
   }
   openDialogLoginAdmin(wallet: string){
    this.matDialog.open(DialogLoginAdminComponent, {disableClose: true, data: wallet})
   }
   openDialogDelivery(itemInfo: any){
    this.matDialog.open(DeliveryDialogComponent, {disableClose: true, data: itemInfo})
   }
   openDialogChangeOrder(itemInfo: any){
    this.matDialog.open(DialogChangeOrderComponent, {disableClose: true, data: itemInfo})
   }
   openDialogAuction(itemInfo: any){
    this.matDialog.open(DialogAuctionComponent, {data: itemInfo})
   }
}
