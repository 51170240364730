import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OnboardService } from 'src/app/services/onboard.service';
import { UserService } from 'src/app/services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogAuthComponent } from '../dialog-auth/dialog-auth.component';


@Component({
  selector: 'app-dialog-login-admin',
  templateUrl: './dialog-login-admin.component.html',
  styleUrls: ['./dialog-login-admin.component.css']
})
export class DialogLoginAdminComponent implements OnInit {

  email: string
  password: string
  loading: boolean = false
  loaderText: string = ''

  constructor(
    public dialogRef: MatDialogRef<DialogAuthComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public wallet: string,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private onboardService: OnboardService
    ) { 
    this.email = ''
    this.password = ''
    }

  ngOnInit(): void {
  }


  loginAdminDGalery() {
    const params = {wallet: this.wallet, email: this.email, password: this.password} 
    this.loaderText = this.translate.instant('Wait a moment, we are importing your gallery');
    this.loading = true;
    this.userService.loginDGalery(params).subscribe({
      next: (response: any) => {
        this.loading = false;
        localStorage.setItem('token', response.token);
        this.userService.refreshUser();
        this.dialogRef.close();
        this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
      },
      error: (error: any) => {
        this.loading = false;
        this.snackBar.open(this.translate.instant('Error occurred please try again later'), '', { duration: 2000 });
      }
    })
    
  }

}
