import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { error } from 'console';
import { ItemService } from 'src/app/services/item.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delivery-dialog',
  templateUrl: './delivery-dialog.component.html',
  styleUrls: ['./delivery-dialog.component.css']
})
export class DeliveryDialogComponent implements OnInit {

  currentUser: any;

  name: string = '';
  email: string = '';
  phone: string = '';
  country: string = '';
  zipCode: string = '';
  address: string = '';
  province: string = '';
  dataSent: boolean = false;
  spinner: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService, 
    private router: Router, 
    private itemService: ItemService,
    private loader: LoaderService
    ) { 

    this.userService.user.subscribe((user) => {
      this.currentUser = user;

      if(this.currentUser){
        this.name = this.currentUser.names;
        this.email = this.currentUser.email;
      }
    });
  }

  ngOnInit(): void {
  }


  sendDeliveryInfo(){

    if (!this.name || !this.email || !this.phone || !this.country || !this.zipCode || !this.address || !this.province) {
      return;
    }
    this.spinner = true;
    const body = {
      names: this.name,
      email: this.email,
      tokenId: this.data,
      buyer: this.currentUser.id,
      phone: this.phone,
      country: this.country,
      zipCode: this.zipCode,
      direction: this.address,
      province: this.province
    }

    this.spinner = true;
    this.itemService.sendShippingData(body).subscribe((res) => {
      this.spinner = false;
      this.dataSent = true;
    }, error => {
      this.spinner = false;
    });
  }

  closeDialog() {
      window.location.reload();
  }

}
