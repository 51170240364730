<div class="d-flex flex-column align-items-center justify-content-center pb-4" 
style="padding-bottom: 150px !important; padding-top: 100px !important;"> 

    <h2 class="special-text text-center" translate>
        {{text ? text : "You don't have NFTs in your collection yet!"}}
    </h2> 
    
    <button
    *ngIf="!hideBtn"
    style="
      background: #ff004f;
      border: none;
      font-weight: bold;
      padding: 8px 29px;
      color: white !important;
      margin-top: 0.7rem;
    "
    (click)="routerLink()" 
  >
    <span translate>{{btnText ? btnText : "Start collecting NFTs and be part of the creative revolution."}}</span>
  </button>
</div>
