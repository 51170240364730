<div class="appContainer2">
  <div class="nft-main">
    <section
      id="section-hero"
      aria-label="section"
      class="vh-100 no-top"
      style="
        background: url('./../../../assets/images/backgroundslides4.png') center
          bottom / cover;
      
      "
    >
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators mb-5">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="v-center" style="background-size: cover">
              <div
                class="container gpt3__header"
                style="background-size: cover"
              >
                <div
                  class="gpt3__header-content col-md-6 ml-4"
                  style="background-size: cover"
                >
                  <h2
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 1px 1px 2px black,
                      0 0 1em red,
                      0 0 0.2em crimson;
                    "
                    translate
                  >
                    Principal Slide 1 Title
                  </h2>
                  <p
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 3px 3px 4px black;
                    "
                    translate
                  >
                    Principal Slide 1
                  </p>
                  <a
                    href="javascript:void(0)"
                    routerLink="/marketplace"
                    class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                    data-wow-delay="1.25s"
                    style="
                      visibility: visible;
                      animation-delay: 1.25s;
                      animation-name: fadeInUp;
                    "
                    translate
                    >explore</a
                  >
                </div>
                <div class="gpt3__header-image ">
                  <img src="assets/images/home.png" alt="Home" />
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="v-center" style="background-size: cover">
              <div
                class="container gpt3__header"
                style="background-size: cover"
              >
                <div
                  class="gpt3__header-content col-md-6 ml-4"
                  style="background-size: cover"
                  >
                  <h2
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 1px 1px 2px black,
                      0 0 1em red,
                      0 0 0.2em crimson;
                    "
                    translate
                  >
                    Principal Slide 2 Title
                  </h2>
                  <p
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 3px 3px 4px black;
                    "
                    translate
                  >
                    Principal Slide 2
                  </p>
                  <a
                    href="javascript:void(0)"
                    routerLink="/marketplace"
                    class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                    data-wow-delay="1.25s"
                    style="
                      visibility: visible;
                      animation-delay: 1.25s;
                      animation-name: fadeInUp;
                    "
                    translate
                    >explore</a
                  >
                </div>
                <div class="gpt3__header-image">
                  <img src="assets/images/home2.png" alt="Home" />
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="v-center" style="background-size: cover">
              <div
                class="container gpt3__header"
                style="background-size: cover"
              >
                <div
                  class="gpt3__header-content col-md-6 ml-4"
                  style="background-size: cover"
                >
                  <h2
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 1px 1px 2px black,
  0 0 1em red,
  0 0 0.2em crimson;
                    "
                    translate
                  >
                    Principal Slide 3 Title
                  </h2>
                  <p
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 3px 3px 4px black;
                    "
                    translate
                  >
                    Principal Slide 3
                  </p>
                  <a
                    href="javascript:void(0)"
                    routerLink="/marketplace"
                    class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                    data-wow-delay="1.25s"
                    style="
                      visibility: visible;
                      animation-delay: 1.25s;
                      animation-name: fadeInUp;
                    "
                    translate
                    >explore</a
                  >
                </div>
                <div class="third-image">
                  <img src="assets/images/home3.png" alt="Home"/>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="v-center" style="background-size: cover">
              <div
                class="container gpt3__header"
                style="background-size: cover"
              >
                <div
                  class="gpt3__header-content col-md-6 ml-4"
                  style="background-size: cover"
                >
                  <h2
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 1px 1px 2px black,
                      0 0 1em red,
                      0 0 0.2em crimson;
                    "
                    translate
                  >
                    Principal Slide 4 Title
                  </h2>
                  <p
                    class="wow fadeInUp animated"
                    data-wow-delay=".75s"
                    style="
                      visibility: visible;
                      animation-delay: 0.75s;
                      animation-name: fadeInUp;
                      color: white;
                      text-shadow: 3px 3px 4px black;
                    "
                    translate
                  >
                    Principal Slide 4
                  </p>
                  <a
                    href="javascript:void(0)"
                    routerLink="/marketplace"
                    class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                    data-wow-delay="1.25s"
                    style="
                      visibility: visible;
                      animation-delay: 1.25s;
                      animation-name: fadeInUp;
                    "
                    translate
                    >explore</a
                  >
                </div>
                <div class="gpt3__header-image">
                  <img src="assets/images/home4.png" alt="Home" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section
      id="section-intro"
      class="no-top no-bottom"
      style="background-color: #000000"
      *ngIf="adminArt.length > 0"
    >
      <div class="container" style="background-size: cover">
        <div class="row" style="background-size: cover">
          <div class="col-lg-12" style="background-size: cover">
            <div class="text-center" style="background-size: cover">
              <h1
                translate
                class="style-2"
                style="color: #fff; padding-top: 5rem"
              >
              DGalery NFT + Painting Auctions
              </h1>
              <div class="spacer-single"></div>
            </div>
          </div>
        </div>
        <div class="row" style="background-size: cover">
          <div class="row wow fadeIn">
            <div class="container">
              <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of adminArt.slice(0, 8)">
                    <app-card [item]="item"> </app-card>
                  </div>
              </div>
            </div>
            

            <div class="text-center">
              <button
                style="
                  background: #c5a500;
                  border: none;
                  font-weight: bold;
                  padding: 8px 29px;
                  color: white !important;
                  margin-top: -1rem;
                  margin-bottom: 3rem;
                "
                routerLink="/marketplace"
                [queryParams]="{ collection: 'DGalery' }"
              >
                <span translate> VER MAS </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>


<!-- aqui es el nuevo acrrusel -->
    <section
      id="section-widg"
      aria-label="section"
      class="gpt4__section"
      class="pt-4">
      <div  style=" background: url('../../../assets/images/backgroundsection2.png') center" >
        <h1
          class="text-center"
          style="color: white; padding-top: 3vw; padding-bottom: 1vw;"
          translate
        >
          widg Title
        </h1>
        <div class="row mt-4" >
          <div class="col-md-5">
            <div class="gpt4__header-img">
              <img
                src="./../../../assets/images/Dgalery.png"
                alt="Home"
                
              />
            </div>
          </div>
          <div class="col-md-6" >
            <div id="carouselExampleIndicators2" class="carousel slide gpt4__header-carousel" data-ride="carousel">
              <ol class="carousel-indicators indicator2">
                <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div
                    class="v-center"
                    style="background-size: cover; min-height: 400px"
                  >
                    <div class="container gpt3__header">
                      <div class="gpt4__header-content" style="padding-left: 8%">
                        <h2
                          class="gpt4__header_title  fadeInUp animated "
                          data-wow-delay=".75s"
                         
                          translate
                        >
                          widg 1 Title
                        </h2>
                        <p
                          class="wow fadeInUp animated gpt4__header-text"
                          data-wow-delay=".75s"
                        
                          translate
                        >
                          widg 1
                        </p>

                        <h2
                        class="wow fadeInUp animated gpt4__header_title"
                        data-wow-delay=".75s"
                        translate
                      >
                          widg 2 Title
                        </h2>
                        <p
                          class="wow fadeInUp animated gpt4__text gpt4__header-text"
                          data-wow-delay=".75s"
                          translate
                        >
                          widg 2
                        </p>
                        <a
                          href="http://app.dgalery.com
                          "
                          class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                          data-wow-delay="1.25s"
                          style="
                            visibility: visible;
                            animation-delay: 1.25s;
                            animation-name: fadeInUp;
                          "
                          translate
                          >widg btn</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div
                    class="v-center"
                    style="background-size: cover; min-height: 400px"
                  >
                    <div class="container gpt3__header">
                      <div class="gpt4__header-content" style="padding-left: 8%">
                        <h2
                          class="wow fadeInUp animated gpt4__header_title"
                          data-wow-delay=".75s"
                          translate
                        >
                          widg 4 Title
                        </h2>
                        <p
                        class="wow fadeInUp animated gpt4__text gpt4__header-text"
                        data-wow-delay=".75s"
                        translate
                      >
                          widg 4
                        </p>

                        <h2
                          class="wow fadeInUp animated gpt4__header_title"
                          data-wow-delay=".75s"
                          translate
                        >
                          widg 5 Title
                        </h2>
                         <p
                          class="wow fadeInUp animated gpt4__text gpt4__header-text"
                          data-wow-delay=".75s"
                          translate
                        >
                          widg 5
                        </p>
                        <a
                          href="http://app.dgalery.com
                          "
                          class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                          data-wow-delay="1.25s"
                          style="
                            visibility: visible;
                            animation-delay: 1.25s;
                            animation-name: fadeInUp;
                          "
                          translate
                          >widg btn</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div
                    class="v-center"
                    style="background-size: cover; min-height: 400px"
                  >
                    <div class="container gpt3__header">
                      <div class="gpt4__header-content" style="padding-left: 8%">
                        <h2
                        class="wow fadeInUp animated gpt4__header_title"
                        data-wow-delay=".75s"
                        translate
                      >
                          widg 3 Title
                        </h2>
                        <p
                        class="wow fadeInUp animated gpt4__text gpt4__header-text"
                        data-wow-delay=".75s"
                        translate
                      >
                          widg 3
                        </p>
                        <a
                          href="http://app.dgalery.com
                          "
                          
                          class="hide-mobil newbtn btnsize3 wow fadeInUp lead animated"
                          data-wow-delay="1.25s"
                          style="
                            visibility: visible;
                            animation-delay: 1.25s;
                            animation-name: fadeInUp;
                          "
                          translate
                          >widg btn</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section style="background-color: #000000; margin-top: -90px;">
      <div class="container">
          <div class="container" style="display: flex; justify-content: center; gap: 10px; margin-bottom: 3rem;">
              <div class="text">
                  <h1 translate style="color: white">Como funciona</h1>
              </div>
          </div>
      </div>
      <div class="container" *ngIf="!showVideo">
          <div class="row" style="background-size: cover;">
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                  style="background-size: cover; margin-bottom: 4rem;">
                  <app-section-two-items [img]="'../../../assets/images/teclado.webp'"
                      [name]="'hwt 1'"
                      [description]="'hwd 1'"></app-section-two-items>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                  style="background-size: cover; margin-bottom: 4rem;">
                  <app-section-two-items [img]="'../../../assets/images/arte.webp'"
                      [name]="'hwt 2'"
                      [description]="'hwd 2'"></app-section-two-items>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                  style="background-size: cover; margin-bottom: 4rem;">
                  <app-section-two-items [img]="'../../../assets/images/nft.webp'" 
                      [name]="'hwt 3'"
                      [description]="'hwd 3'"></app-section-two-items>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                  style="background-size: cover; margin-bottom: 4rem;">
                  <app-section-two-items [img]="'../../../assets/images/comercio.webp'"
                      [name]="'hwt 4'"
                      [description]="'hwd 4'"></app-section-two-items>
              </div>
          </div>
      </div>
      <div class="container">
        <iframe *ngIf="showVideo" width="1280" height="720" src="https://www.youtube.com/embed/LikCPHJDVrY?autoplay=1" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="text-center">
        <button
          style="
            background: #ff004f;
            border: none;
            font-weight: bold;
            padding: 8px 29px;
            color: white !important;
            margin-top: 1rem;
          "
          (click)="showVideo = !showVideo"
        >
          <span *ngIf="!showVideo" translate>WATCH VIDEO</span>
          <span *ngIf="showVideo" translate>CLOSE VIDEO</span>
        </button>
      </div>
  </section> -->

    <section id="section-intro" class="no-top no-bottom" 
    style="background-color: #000000" style="margin-top: -90px;" 
    *ngIf="communityArt.length > 0">
      <div class="container" style="background-size: cover">
        <div class="row" style="background-size: cover">
          <div class="col-lg-12" style="background-size: cover">
            <div class="text-center" style="background-size: cover">
              <h1
                translate
                class="style-2"
                style="color: #fff; padding-top: 5rem"
              >
                Community Artwork
              </h1>
              <div class="spacer-single"></div>
              <!-- <button (click)="verComunity()">aaa</button> -->
            </div>
          </div>
        </div>
        <div class="row" style="background-size: cover">
          <div class="row wow fadeIn">
            <div class="container">
              <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of communityArt">
                    <app-card [item]="item"> </app-card>
                  </div>
              </div>
          </div>

            <div class="text-center">
              <button
                style="
                  background: #ff004f;
                  border: none;
                  font-weight: bold;
                  padding: 8px 29px;
                  color: white !important;
                  margin-top: 1rem;
                "
                routerLink="/marketplace"
                [queryParams]="{ collection: 'communityArt' }"
              >
                <span translate> VER MAS </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="section-intro" style="background-color: #000000; padding: 90px 0 0 0;" *ngIf="collectionItemsSubastas.length > 0">
      <div class="container" style="background-size: cover">
        <div class="row" style="background-size: cover">
          <div
            class="col-lg-12"
            style="background-size: cover;"
          >
            <div class="text-center" style="background-size: cover">
              <h2 translate style="color: white">Auctions</h2>
            </div>
          </div>
        </div>


        <div class="row" style="background-size: cover">
          <div class="row wow fadeIn">
            <div class="container">
              <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of collectionItemsSubastas">
                    <app-card [item]="item"> </app-card>
                  </div>
              </div>
            </div>
            

            <div class="text-center">
              <button
                style="
                  background: #ff004f;
                  border: none;
                  font-weight: bold;
                  padding: 8px 29px;
                  color: white !important;
                  margin-top: -1rem;
                  margin-bottom: 3rem;
                "
                routerLink="/marketplace"
              >
                <span translate> VER MAS </span>
              </button>
            </div>
          </div>
        </div>

      </div>
    </section>


    <section id="section-intro" class="no-top no-bottom" style="background-color: #000000" *ngIf="listTopSellers.length > 0">
    <div class="container" style="background-size: cover">
      <div class="row" style="background-size: cover">
        <div class="col-lg-12" style="background-size: cover">
          <div class="text-center" style="background-size: cover">
            <h1 translate class="style-2" style="color: #fff; padding-top: 2rem">
              Top sellers
            </h1>
            <div class="spacer-single"></div>
            <!-- <button (click)="verComunity()">aaa</button> -->
          </div>
        </div>
      </div>
      <div class="row" style="background-size: cover">
        <div class="row wow fadeIn">
          <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of listTopSellers.slice(0, 4)">
                  <app-card-seller [item]="item"> </app-card-seller>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

    

    <section style="background: #000000">
      <div class="container">
        <div
          class="container"
          style="
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-bottom: 2rem;
          "
        >
          <div class="text">
            <h1 translate style="color: white">FAQs</h1>
          </div>
        </div>
        <div class="faq-list">
          <app-faq-item *ngFor="let faq of faqs" [faq]="faq"></app-faq-item>
        </div>
      </div>
    </section>

    <section
      style="
        background: url('../../../assets/images/backgroundsection2.png') center
          bottom / cover;
      "
    >
      <div>
        <div class="container">
          <form (ngSubmit)="onSubmit()" class="contact-form">
            <div
              class="container"
              style="
                display: flex;
                justify-content: center;
                gap: 10px;
                margin-bottom: 3rem;
              "
            >
              <div class="text">
                <h1 translate style="color: white">Contacto</h1>
              </div>
            </div>
            <div class="contact-form-input">
              <input
                type="text"
                class="form-control rounded border-white form-input"
                [(ngModel)]="name"
                style="background: transparent; color: white"
                name="name"
                placeholder="{{'Name' | translate}}"
                required
              />
            </div>
            <div class="contact-form-input">
              <input
                type="email"
                class="form-control rounded border-white form-input"
                [(ngModel)]="email"
                style="background: transparent; color: white"
                name="email"
                placeholder="{{'Email' | translate}}"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              />
            </div>
            <div class="contact-form-input">
              <textarea
                class="form-control rounded border-white mb-3 form-text-area"
                [(ngModel)]="message"
                name="message"
                rows="5"
                cols="30"
                placeholder=" {{'Message' | translate}}"
                style="background: transparent; color: white"
                required
              ></textarea>
            </div>
            <div class="justify-content-center text-center d-flex p-2">
              <re-captcha
                #captchaRef
                (resolved)="resolved($event)"
                siteKey="6LcfNb4pAAAAABYaw-P9EvP3E_lTbSq5zw9rnXEK"
                ></re-captcha>
            </div>
            <div class="text-center">
              <button
                style="
                  background: #ff004f;
                  border: none;
                  font-weight: bold;
                  padding: 8px 29px;
                  color: white !important;
                  margin-top: 1rem;
                "
                type="submit"
              >
                <span *ngIf="!isSending; else sending" translate> SEND </span>
                <ng-template #sending>
                  <mat-progress-spinner
                    style="background-color: gray; border-radius: 40px"
                    diameter="20"
                    mode="indeterminate"
                  ></mat-progress-spinner>
                </ng-template>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section style="background-color: #000000">
      <div class="container" style="margin-bottom: 3rem">
        <div class="text-center">
          <h1 translate style="color: #ff004f">Join to the community</h1>
        </div>
        <div>
          <p style="color: white !important" class="text-center" translate>
            Join the creative revolution
        </div>
        <div class="text-center">

          <button
            style="
              background: #ff004f;
              border: none;
              font-weight: bold;
              padding: 8px 29px;
              color: white !important;
              margin-top: 1rem;
            "
          >
            <span translate>Connect Wallet</span>
          </button>
        </div>
        <div
          class="d-flex align-items-center justify-content-center mt-5 social__media__icons"
          style="color: #fff; justify-content: space-between"
        >
          <img
            src="../../../assets/social-media-icons/twitter-x.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/instagram.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/discord.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/reddit.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/youtube.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/tiktok.svg"
            class="mx-auto"
          />
          <img
            src="../../../assets/social-media-icons/email.svg"
            class="mx-auto"
          />
        </div>
      </div>
    </section>
  </div>
</div>
