import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-for-loader-srv',
  templateUrl: './for-loader-srv.component.html',
  styleUrls: ['./for-loader-srv.component.css']
})
export class ForLoaderSrvComponent implements OnInit {
  @Input() loadingText!: string;

  isLoading: boolean = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((value) => {
      this.isLoading = value;
      //console.log('cambio el spinner a: ', this.isLoading)
    });
   }

  ngOnInit(): void {
  }

}
