import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OnboardService } from 'src/app/services/onboard.service';

@Component({
  selector: 'app-dialog-auction',
  templateUrl: './dialog-auction.component.html',
  styleUrls: ['./dialog-auction.component.css']
})
export class DialogAuctionComponent implements OnInit {

  initialPrice = this.data.price
  price = this.data.price 
  spinner = false;  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private onboardService: OnboardService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.setFactorIncrement();
  }

  async setFactorIncrement() {
    const factorIncrement = await this.getIncrement();
    this.price = this.price * factorIncrement;
    this.initialPrice = this.initialPrice * factorIncrement;
  }

  async getIncrement() {
    const factorIncrement = await this.onboardService.getMinIncerement();
    return factorIncrement;
  }

  handleInputChange() {
    if (this.price < this.initialPrice ) {
      this.price = this.initialPrice;
    }
  }

  placeBid() {

    const constNewBid = {
      orderId: this.data.orderId,
      price: this.price,
    }

    this.onboardService.placeBid(constNewBid).then((res: any) => {
      this.dialogService.openDialogCustom({title: 'Success', content: "Your bid was placed successfully.", btnText: 'Accept'});
      this.spinner = false;
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      

    }).catch((err: any) => {
      this.spinner = false;

    });
  }


  checkBalance() {

    this.spinner = true;
    this.onboardService.balanceOfUsdt().then((result: any) => {
      const balance = result 
      if(balance < this.price ) throw new Error('0001');
      this.aproveBid();
    }).catch(e => {
      if (e.message == '0001') {
        this.dialogService.openDialogCustom({title: "You don't have enough balance", content: "You don't have enough balance to buy this NFT. Add founds to your wallet and then try again.", btnText: 'Accept'});
      } else {
        this.dialogService.openDialogCustom({title: 'Error', content: "An error occurred, if the error persists try again later.", btnText: 'Accept'});
      }
      this.spinner = false;
    });
  }


  aproveBid() {
    this.onboardService.approveUSDT(this.price).then((res: any) => {
      this.placeBid();
    }).catch((err: any) => {
      this.dialogService.openDialogCustom({title: 'Error', content: "An error occurred, if the error persists try again later.", btnText: 'Accept'});
      this.spinner = false;
    });
  }


}
