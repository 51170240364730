import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Common } from '../models/common';
import { API } from '../constants/api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Response } from '../models/request';

declare var window: any;
const url = environment.url;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public notifier: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  private imageProfileSubject: BehaviorSubject<any> = new BehaviorSubject<string>('');
  imageProfile$ = this.imageProfileSubject.asObservable();
  private bannerSubject: BehaviorSubject<any> = new BehaviorSubject<string>('');
  banner$ = this.bannerSubject.asObservable();

  constructor(
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<any>(this.getUser());
    this.user = this.userSubject.asObservable();
    this.user.subscribe(user => {
      if (user) {
        this.imageProfileSubject.next(`${API.dgalery_url}/static/uploads/profile-${user.idDgalery}.png?t=${Date.now()}`);
        this.bannerSubject.next(`${API.url}/images/profiles/bn-${user.id}.png?t=${Date.now()}`);        
      }
    });
  }

  updateImageProfile(newImageProfile: string) {
    this.imageProfileSubject.next(newImageProfile);
  }

  updateBanner(newBanner: string) {
    this.bannerSubject.next(newBanner);
  }

  /**
   * This is the function which used to get user jwt token for local storage
   */
  getToken = () => {
    const token = localStorage.getItem('token');
    return token == null ? '' : token;
  }

  refreshUser = () => this.userSubject.next(this.getUser());

  /**
   * This is the function which used to parse jwt token using helper service
   */
  getUser() {
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    if (token?.length > 0 /*&& window.ethereum?.selectedAddress?.length > 0*/) {
      return jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  getExpirationToken(){
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    if (token?.length > 0){
      return jwtHelper.isTokenExpired(token);
    } else {
      return null;
    }
  }


/*
  loginMetamask(wallet: any): Observable<any> {
    //return this.http.get<any>(`http://192.168.1.178:3050/api/v1/signin/${wallet}`)
    return this.http.get<any>(`https://apimpdgalery.devtop.online/api/v1/signin/${wallet}`)
  } */


  /*
  loginWithWallet(wallet: any) {
    const url = `${API.base_url}  ${API.login_wallet} ${wallet}`
    return this.http.get(url, {responseType: 'json'})
  } */

  login2(wallet: any) {
    return this.http.get(`${API.url}/signin/${wallet}`)
  }

  logOut() {
    return this.http.delete(`${API.url}/logout`)
  }

  signupWallet(body: any): Observable<any> {
    // Definir el cuerpo del JSON
    //const body = { wallet: wallet };

    // Configurar las cabeceras para la solicitud POST
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Realizar solicitud POST
    return this.http.post(`${API.url}/signup`, body, { headers: headers });
  }

  loginDGalery(params: any): Observable<any> {

    // Configurar las cabeceras para la solicitud POST
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Realizar solicitud POST
    return this.http.post<any>(`${API.url}/signin/DGalery`, params, { headers: headers });
  }

  getTopSellers(): Observable<any> {
    return this.http.get<any>(`${API.url}/topSellers`)
  }

  loginApi(params: any): Observable<Common> {
    const url = API.base_url + API.user_login;
    return this.http.post<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  getChallenge(wallet: string): Observable<any> {
    const url = `${API.url}/auth/${wallet}`;
    return this.http.get<any>(url);
  }

  loginWallet(message: string, signature: string): Observable<Common> {
    const url = `${API.base_url}${API.user_auth}/${message}/${signature}`;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  register(params: any): Observable<Common> {
    const url = API.base_url + API.user_register;
    return this.http.post<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }
 
  uploadImage(file: any, route:string): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);

    return this.http.post<any>(`${API.url}/${route}`, formData)
  }

  uploadProfileImage(file: any, userID: any): Observable<any> {
    const formData = new FormData();
    formData.append('userId', userID);
    formData.append('file', file);

    const headers = new HttpHeaders({
      'Accept': 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    });

    return this.http.put<any>(`${API.dgalery_api}/user/upload_profile_image`, formData)
  }

  getHistoryTransactions(page: number): Observable<any> {
    return this.http.get<any>(`${API.url}/userHistorial?page=${page}&limit=5`);
  }
  
  getProfileById(id: string): Observable<any> {
    return this.http.get<any>(`${API.url}/profile/${id}`);
  }

  editProfile(params: any): Observable<any> {
    return this.http.put<any>(`${API.url}/profile`, params);
  }

  getNotification(): Observable<any> {
    return this.http.get<any>(`${API.url}/notification`);
  }

  deleteNotification(id:any): Observable<any> {
    return this.http.delete<any>(`${API.url}/notification/${id}`,);
  }

  bestSellers(): Observable<Common> {
    const url = API.base_url + API.user_best_sellers;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

  forgotPassword(params: any): Observable<Common> {
    const url = API.base_url + API.user_forgot;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserialize(param)));
  }

  resetPassword(params: any): Observable<Common> {
    const url = API.base_url + API.user_reset;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  viewProfile(user_id: any): Observable<Common> {
    const url = API.base_url + API.user_profile + '/' + user_id;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

  updateApi(formData: FormData, user_id: string): Observable<Common> {
    const url = API.base_url + API.user_update + '/' + user_id;
    return this.http.put<Common>(url, formData).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }
  updateMetamaskAddress(params: any): Observable<Common> {
    const url = API.base_url + API.user_update_metamask;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserialize(param)));
  }

  setWallet(wallet: string) {
    if (wallet) return localStorage.setItem('wallet', wallet);
  }

  getWallet(): string {
    const wallet = localStorage.getItem('wallet');
    return wallet? wallet : '';
  }

  deleteWallet() {
    return localStorage.removeItem('wallet');
  }

  setReferId(referId: string) {
    if (referId) return localStorage.setItem('refer_id', referId);
  }

  getReferId(): string {
    const referId = localStorage.getItem('refer_id');
    return referId? referId : '';
  }

  deleteReferId() {
    return localStorage.removeItem('refer_id');
  }

  setMyReferId(myReferId: string) {
    if (myReferId) return localStorage.setItem('my_refer_id', myReferId);
  }

  getMyReferId(): string {
    const myReferId = localStorage.getItem('my_refer_id');
    return myReferId? myReferId : '';
  }

  deleteMyReferId() {
    return localStorage.removeItem('my_refer_id');
  }

  categoryListStudents(): Observable<Common> {
    let url = API.base_url + API.category_list_student;
    return this.http.get<Common>(url).pipe(map((param:any) => new Common().deserializeCategoryListStudents(param)));
  }

  categoryListSportsman(): Observable<Common> {
    let url = API.base_url + API.category_list_sportsman;
    return this.http.get<Common>(url).pipe(map((param:any) => new Common().deserializeCategoryListSportsman(param)));
  }

}
